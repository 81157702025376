import styled from "styled-components"
import React from "react"
import Insights from "./Insights"

const MobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3.3125rem;

  @media (min-width: 64em) {
    display: none;
  }
`

const InsightsMobile = () => {
  return (
    <MobileContainer>
      <Insights />
    </MobileContainer>
  )
}

export default InsightsMobile
