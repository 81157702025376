import styled from "styled-components"
import React from "react"

export const TitleSecondary = styled.h3`
  color: #000000;

  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 600;
  font-size: 1.125em;
  line-height: 15px;
  letter-spacing: 0.05em;

  @media (min-width: 40em) {
    font-size: 1.125em;
    font-weight: 500;
    font-family: var(--ff-primary);
    line-height: 27px;
    letter-spacing: 0.0015em;
  }
`

export const AboutParagraph = styled.p`
  text-align: left;
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 400;
  font-size: 0.875em;
  line-height: 20px;
  letter-spacing: 0.0015em;
  margin-top: 1rem;
  margin-bottom: 2rem;

  @media (min-width: 40em) {
    font-size: 1em;
    font-weight: 400;
    letter-spacing: 0.0015em;
    line-height: 24px;
  }
`

export const ListItemContainer = styled.li`
  display: flex;
  font-style: normal;
  font-family: var(--ff-primary);
  font-weight: 400;
  font-size: 0.875em;
  line-height: 20px;
  letter-spacing: 0.0015em;
  margin-bottom: 0.25rem;

  &:before {
    content: "•";
    font-size: 10pt;
    margin-left: 1em;
    margin-right: 1em;
  }

  @media (min-width: 40em) {
    margin-bottom: 0;
    font-weight: 400;
    font-size: 1em;
    line-height: 24px;
    letter-spacing: 0.0015em;
  }
`

const ListItemContent = styled.div``

export const ListItem = ({ children }) => (
  <ListItemContainer>
    <ListItemContent>{children}</ListItemContent>
  </ListItemContainer>
)

export const Bold = styled.span`
  font-weight: 600;
`
