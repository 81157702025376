import React from "react"
import styled from "styled-components"
import { ShareLinks } from "../common/ShareLinks"
import ApplyButton from "./ApplyButton"

const FooterApplyButton = styled(ApplyButton)`
  @media (max-width: 64em) {
    display: none;
  }
`

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 2.5rem);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5.94rem;

  @media (min-width: 40em) {
    width: 100%;
  }

  @media (min-width: 64em) {
    max-width: 20.1875rem;
    margin: 0;
    margin-top: 2rem;
  }
`

const ShareSection = styled.div`
  width: fit-content;
  gap: 0.5rem;
  display: flex;
  justify-content: space-between;

  a {
    width: 34px;
    height: 32px;
  }

  @media (min-width: 64em) {
    margin-top: 3rem;
    a {
      width: 42px;
      height: 40px;
    }
  }
`

const ShareText = styled.p`
  text-transform: uppercase;
  font-family: var(--ff-secondary);
  font-weight: 700;
  font-size: 1.125em;
  line-height: 30px;
  letter-spacing: 0.05em;
  color: rgba(0, 0, 0, 0.5);
  height: fit-content;
  margin-top: auto;
  margin-bottom: auto;

  margin-right: 0.5rem;
  @media (min-width: 64em) {
    font-weight: 800;
    font-size: 1.25em;
    line-height: 33px;
  }
`
const Footer = () => {
  return (
    <FooterContainer>
      <FooterApplyButton to="apply#form">Apply now</FooterApplyButton>
      <ShareSection>
        <ShareText>Share on:</ShareText>
        <ShareLinks />
      </ShareSection>
    </FooterContainer>
  )
}

export default Footer
