import React from "react"
import styled from "styled-components"
import StoryBlock from "../stories/StoryBlock"

const StorySectionContainer = styled.section`
  margin-top: 2rem;
  @media (min-width: 64em) {
    margin-top: 4rem;
  }
`

const StyledStoryBlock = styled(StoryBlock)`
  margin-top: 2rem;
  margin-left: 0;
  max-width: 450px;
  margin-inline: auto;

  @media (min-width: 64em) {
    margin-inline: unset;
    max-width: 580px;
  }
`

const StoryTitle = styled.h3`
  color: #d2085d;

  font-family: var(--ff-secondary);
  font-style: normal;
  font-weight: 600;
  font-size: 1.125em;
  line-height: 30px;

  text-align: center;
  @media (min-width: 64em) {
    color: black;
    font-weight: 700;
    font-size: 1.5em;
    line-height: 39px;
    text-align: left;
  }
`

const StorySection = ({ title, story }) => {
  return (
    <StorySectionContainer>
      <StoryTitle>{`Read ${title} ${story.summaryTitle}’s story:`}</StoryTitle>
      <StyledStoryBlock story={story} />
    </StorySectionContainer>
  )
}

export default StorySection
