import React from "react"
import { graphql } from "gatsby"
import Meta from "../components/common/Meta"
import styled from "styled-components"
import rehypeReact from "rehype-react"
import PositionLayout from "../components/open-positions/PositionLayout"
import PositionFactsMobile from "../components/open-positions/position-facts/PositionFactsMobile"
import PositionHeader from "../components/open-positions/PositionHeader"
import PositionFooter from "../components/open-positions/PositionFooter"
import StorySection from "../components/open-positions/StorySection"
import {
  AboutParagraph,
  Bold,
  ListItem,
  TitleSecondary,
} from "../components/open-positions/OpenPositionStyles"
import { Fragment } from "react"
import InsightsMobile from "../components/open-positions/rebase-insights/InsightsMobile"

const PositionArticle = styled.article`
  grid-area: jobcontent;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: fit-content;
  position: relative;

  &:before {
    @media (min-width: 64em) {
      content: "";
      position: absolute;
      left: 0;
      width: 5px;
      height: 1050px;
      background-color: var(--clr-secondary-dark);
    }
  }

  @media (min-width: 64em) {
    margin-top: 5.125rem;
    margin-left: 1.845rem;
    padding-left: 4rem;
    padding-right: 5.31rem;
    margin-bottom: 9.5rem;
  }
`

const StyledList = styled.ul`
  list-style-type: none;
  margin-top: 1rem;
  margin-bottom: 2rem;
`

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    h1: TitleSecondary,
    p: AboutParagraph,
    ul: StyledList,
    li: ListItem,
    em: Bold,
  },
  Fragment,
}).Compiler

export const Head = ({ data, pageContext }) => (
  <Meta
    title={
      pageContext?.allPositions
        ? "Join Rebase - Software, Cloud, Data, QA and Business Agility"
        : `Join Rebase - ${data?.positionMd?.frontmatter.mainTitle}`
    }
    description={
      pageContext?.allPositions
        ? "Join the Rebase team! Explore career opportunities in software development, cloud services, data engineering, test automation and business agility. Apply now!"
        : `We are looking for ${data?.positionMd?.frontmatter.mainTitle}s in Helsinki and other parts of Finland.`
    }
  />
)

const PositionApplyTemplate = ({ data }) => {
  let { htmlAst } = data.positionMd

  const {
    mainTitle,
    remote,
    supervisor,
    salary,
    salary_date,
    rate,
    rate_date,
  } = data?.positionMd?.frontmatter

  return (
    <PositionLayout data={data}>
      <PositionArticle>
        <PositionHeader title={mainTitle} remote={remote} />
        {renderAst(htmlAst)}
        <PositionFooter />
        <PositionFactsMobile
          image={data.image}
          supervisor={supervisor}
          salary={salary}
          salaryDate={salary_date}
          rate={rate}
          rateDate={rate_date}
        />
        <InsightsMobile />
        {data.story?.nodes[0]?.frontmatter && (
          <StorySection
            title={mainTitle}
            story={data.story.nodes[0].frontmatter}
          />
        )}
      </PositionArticle>
    </PositionLayout>
  )
}

export default PositionApplyTemplate

export const query = graphql`
  query PositionPage($slug: String, $supervisorImage: String, $story: String) {
    image: file(relativePath: { eq: $supervisorImage }) {
      childImageSharp {
        gatsbyImageData(width: 180, quality: 80, placeholder: BLURRED)
      }
    }
    positionMd: markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        mainTitle
        slug
        remote
        supervisor
        salary
        salary_date
        rate
        rate_date
        story_slug
      }
      htmlAst
      headings {
        value
      }
    }
    story: allMarkdownRemark(
      filter: { frontmatter: { slug: { eq: $story } } }
    ) {
      nodes {
        frontmatter {
          slug
          summaryTitle
          summary
          date
          summaryImage {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        id
      }
    }
  }
`
